<template>
	<div
		class="colculate pa-0 mb-10 bg-eee"
		style="height: 100%;"
	>
		<div
			class="bg-success2"
			>
			
			<v-card
				elevation="0"
				class="text-white pa-3 pt-5"
				tile
				color="success2"
			>
				<v-card-title
					class="pa-0 text-subtitle-1 font-weight-bold"
					
				>
				{{ item.settlementDate | transDate }} {{ item.settlementDate | transWeek}}요일
				</v-card-title>
				
				<v-divider color="white" dark class="mt-2 mb-5" style="height: 2px; max-height: 2px!important;"></v-divider>
				
				<div
					class="text-subtitle-2"
				>
					<div>총 결제 건수 <span class="float-right">{{ totalCount}} 건</span></div>
					<div class="mt-1">결제금액 <span class="float-right">{{ item.paymentAmount | makeComma}} 원</span></div>
					<div class="mt-1">정산 수수료 <span class="float-right ">{{ item.feeAmount | makeComma}} 원</span></div>
			
					<v-divider class="mt-3 mb-3" color="white" ></v-divider>
				
					<div class="mt-3 text-subtitle-1 font-weight-bold">최종 정산금액<span class="float-right">{{ item.settlementAmount | makeComma}}원</span></div>
				</div>
			</v-card>
		</div>
		
		<div class="bg-eee pa-3 pb-10 mb-10 ">
			<v-card
				v-for="(item, index) in items"
				:key="index"
				elevation=1
				class="pa-3 mb-4 text-subtitle-2"
			>
				<v-card-title 
					class="d-block pa-0 text-subtitle-1 font-weight-bold"
				>
					{{ item.productName}}
					<v-btn
						class="float-right rounded-xl mt-2 bg-yellow"
						color="#FDD835"
						outlined
						x-small
					>{{ item.paymentType}}</v-btn>
				</v-card-title>
				
				<v-card-text
					class="pa-0 mt-2"
				>
					<div>
						{{ (item.paymentDateTime).substring(5, 19) }}
					
						<span class="float-right">{{ item.paymentAmount | makeComma}} 원</span>
					</div>
					<div class="mt-2 text-black font-weight-bold">
						정산금액
					
						<span class="float-right">{{ item.feeAmount | makeComma}} 원</span>
					</div>
				</v-card-text>
			</v-card>
			
			<v-card
				v-if="items.length <= 0"
				height="110px"
			>
				<v-card-text
					class="text-center text-subtitle-1 pt-10 text-black font-weight-bold"
				>
					내역이 없습니다
				</v-card-text>
			</v-card>
			
		</div>
	</div>
</template>

<script>
	export default{
		name: 'PaymentRegist'
		,created: function(){
			this.$emit('setProgram', this.program, this.options)
			this.getData(this.$route.params.item_id)
		}
		,props: ['callBack']
		,data: function(){
			return {
				program: {
					title: '정산 내역'
				}
				,inquiryDate: ''
				,totalCount: 0
				,item: {
					id: 1
					,date: '2020년9월30일'
					,week: '수요일'				
					,card_count: 5
					,payment_price: 1500000
					,payment_fee: 15000
					,total_price: 1485000
				}
				,items: [
				]
				,items1: [
					{
						id: 1
						,product_name: '상품명1'
						,payment_type: '신용카드'
						,payment_date: '2020-09-30 14:55:29'
						,payment_price: 1500000
						,colculate_price: 1585000
					}
					,{
						id: 2
						,product_name: '상품명2'
						,payment_type: '신용카드'
						,payment_date: '2020-09-30 14:55:29'
						,payment_price: 1500000
						,colculate_price: 1585000
					}
					,{
						id: 3
						,product_name: '상품명3'
						,payment_type: '신용카드'
						,payment_date: '2020-09-30 14:55:29'
						,payment_price: 1500000
						,colculate_price: 1585000
					}
					,{
						id: 4
						,product_name: '상품명4'
						,payment_type: '신용카드'
						,payment_date: '2020-09-30 14:55:29'
						,payment_price: 1500000
						,colculate_price: 1585000
					}
					,{
						id: 5
						,product_name: '상품명5'
						,payment_type: '신용카드'
						,payment_date: '2020-09-30 14:55:29'
						,payment_price: 1500000
						,colculate_price: 1585000
					}
					,{
						id: 6
						,product_name: '상품명6'
						,payment_type: '신용카드'
						,payment_date: '2020-09-30 14:55:29'
						,payment_price: 1500000
						,colculate_price: 1585000
					}
				]
			}
		}
		,methods: {
			getData: function(inquiryDate){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/settlement/day/summary'
					,request_params: {
						inquiryDate: inquiryDate
					}
					,authorize: true
					,callBack: 'setItem'
				})
			}
			,getItems: function(inquiryDate){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/settlement/day/detail'
					,request_params: {
						inquiryDate: inquiryDate
					}
					,authorize: true
					,callBack: 'setItems'
				})
			}
			,setItem: function(call){
				
				this.item =	call.items.content
				
				this.getItems(call.items.content.settlementDate)
				
				call = {
					product_name: '상품명'
					,order_price: 125000
					,order_phone: '123-4567-8900'
					,order_fee: '면세'
					,card_num: '1234-56##-####-7890'
					,order_number: '123456789'
					,card_type: 'Master'
					,payment_type: '일시불'
					,date: '2020.10.10 11:22:33'
					,order_price1: 913
					,order_fee_price: 91
					,total_price: 1004
					,company_name: '상점명'
					,company_number: '123456789'
					,company_ceo: '대표자명'
					,company_addr: '상점 주소'
					,state_code: '0'
				}
			}
			,setItems: function(call){
				console.log(call)
				this.items = call.items.content.content
				this.totalCount = call.items.content.totalElements
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					console.log('watch calculate detail : ' + call.name)
					if(call.name == 'setItem'){
						this.setItem(call)
					}else if(call.name == 'setItems'){
						this.setItems(call)
					}
				}
			}
		}
	}
</script>

<style>
	.colculate {}
</style>